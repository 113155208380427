import React, { useEffect, useState } from "react";
import { Button, TextField, Box } from "@mui/material";
import { Backdrop } from "@mui/material";
import { BaseDirectory, createDir, exists } from "@tauri-apps/api/fs";
import { appDataDir } from "@tauri-apps/api/path";

import toast from "react-hot-toast";
import { useStationAuth } from "../contexts/StationAuthContext";

type Props = {};

export default function TauriInitializer({}: Props) {
  const { showLoginForm, setShowLoginForm, isTauriApp, initializeStation } =
    useStationAuth();

  const [showSecret, setShowSecret] = useState(false);
  const [secret, setSecret] = useState("");

  useEffect(() => {
    const initializeDir = async () => {
      await createDir("", { dir: BaseDirectory.AppData, recursive: true });
      console.log("Checking for station-cert.pem", await appDataDir());
      if (!(await exists("station-cert.pem", { dir: BaseDirectory.AppData }))) {
        setShowSecret(true);
      } else {
        setShowSecret(false);
      }
    };
    initializeDir();
  }, [isTauriApp]);

  const handleGenerate = async () => {
    if (secret === "") {
      toast.error("Secret cannot be empty");
      return;
    }
    try {
      await initializeStation(secret);
      setShowSecret(false);
    } catch (e) {
      console.error(e);
      toast.error("Failed to initialize's station's secret");
    }
  };

  if (showSecret) {
    return (
      <>
        <Backdrop
          className="backdrop"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex" }}
          open={true}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              fullWidth
              label="Secret"
              name="secret"
              type="text"
              autoComplete="off"
              autoFocus
              value={secret}
              onChange={(e) => setSecret(e.target.value)}
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  // "& fieldset": {
                  //   borderColor: "white", // This will change the border color
                  // },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white", // This will change the label color
                  "&.Mui-focused": {
                    color: "white",
                  },
                },
                "& .MuiFilledInput-input": {
                  backgroundColor: "lightgray", // change this to your desired color
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleGenerate}
              size="large"
              sx={{
                mt: 2,
                ml: 2,
              }}
            >
              Confirm
            </Button>
          </Box>
        </Backdrop>
      </>
    );
  }

  return (
    <>
      <Backdrop
        className="backdrop"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex" }}
        open={showLoginForm}
        onClick={() => {
          setShowLoginForm(false);
        }}
      >
        <Box
          sx={{
            m: "auto",
            display: "grid",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              // Centering GIF
              marginLeft: "150px",
              marginTop: "150px",
              userSelect: "none",
            }}
            height={500}
            width={500}
            src="/Tap.gif"
            alt="Loading..."
          />
        </Box>
      </Backdrop>
    </>
  );
}
