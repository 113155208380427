import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import useCollapseDrawer from "../hooks/useCollapseDrawer";
import { useStationAuth } from "../contexts/StationAuthContext";
import Iconify from "../components/Iconify";
import { useState } from "react";
import TermsAndConditions from "../components/Terms&Conditions";
import SweepstakesRules from "../components/SweepstakesRules";

// ----------------------------------------------------------------------

const NAVBAR = {
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 102,
};

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick } = useCollapseDrawer();

  return (
    <Box
      sx={{
        display: { lg: "flex" },
        minHeight: { lg: 1 },
      }}
    >
      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
      <Sidebar />
    </Box>
  );
}

const Sidebar = () => {
  const { aleonTokens } = useStationAuth();
  const [showVirtualPetsDialog, setShowVirtualPetsDialog] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showSweepstakesRules, setShowSweepstakesRules] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          background:
            "linear-gradient(to bottom right, rgba(255, 165, 0, 0.95), rgba(255, 69, 0, 0.95))",
          backgroundSize: "cover",
          backgroundBlendMode: "overlay",
          width: "300px",
          zIndex: 99,
          "@media (max-width: 1200px)": {
            display: "none",
          },
        }}
      >
        {/* Tokens */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setShowVirtualPetsDialog(true);
            }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              justifyItems: "center",
              background: "#f7ba4c",
              borderRadius: "5px",
              padding: "15px",
              height: "60px",
              minWidth: "200px",
              mt: 2,
              mb: 1,
              boxShadow: "inset 0 0 5px #9b6733",
            }}
          >
            <Iconify icon="ri:question-line" color="white" fontSize="25px" />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  color: "white",
                  mx: 1,
                  textAlign: "center",
                }}
              >
                {aleonTokens ? parseInt(aleonTokens).toLocaleString() : "0"}
              </Typography>
              <img src="/images/coinGold.gif" alt="Coin" width="30px" />
            </Box>
          </Button>
        </Box>

        {/* Virtual Pets */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src="/logo_title.png"
            alt="pet"
            width="150px"
            style={{ marginBottom: 8 }}
          />
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              fontStyle: "italic",
              textAlign: "center",
            }}
          >
            VIRTUAL PETS
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              fontStyle: "italic",
              textAlign: "center",
            }}
          >
            COMING SOON!
          </Typography>
          <Box
            sx={{
              position: "relative",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            <img
              src="/images/egg.gif"
              alt="egg"
              width="175px"
              onClick={() => {
                setShowVirtualPetsDialog(true);
              }}
              style={{ cursor: "pointer" }}
            />
          </Box>

          <Button
            className="slanted-button"
            onClick={() => {
              setShowVirtualPetsDialog(true);
            }}
            sx={{
              mt: 1,
              mb: 1,
              height: "50px",
              px: 4,
              borderRadius: "5px",
              backgroundColor: "white",
              filter: "drop-shadow(0px 0px 10px white)",
              boxShadow: "0px 0px 10px 0px black",
              "&:hover": {
                backgroundColor: "#f7ba4c",
              },
            }}
          >
            <Typography
              sx={{
                color: "black",
                textTransform: "uppercase",
                my: "auto",
                fontWeight: "bold",
              }}
            >
              Learn More
            </Typography>
          </Button>
        </Box>

        {/* Terms & Conditions */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100px",
          }}
        >
          <Button
            className="slanted-button"
            onClick={() => {
              setShowSweepstakesRules(true);
            }}
            sx={{
              height: "25px",
              px: 1,
              borderRadius: "5px",
              backgroundColor: "white",
              filter: "drop-shadow(0px 0px 10px white)",
              boxShadow: "0px 0px 10px 0px black",
              "&:hover": {
                backgroundColor: "#f7ba4c",
              },
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Sweepstakes Rules
            </Typography>
          </Button>
          <Button
            className="slanted-button"
            onClick={() => {
              setShowTermsAndConditions(true);
            }}
            sx={{
              mt: 1.5,
              mb: 2,
              height: "25px",
              px: 1,
              borderRadius: "5px",
              backgroundColor: "white",
              filter: "drop-shadow(0px 0px 10px white)",
              boxShadow: "0px 0px 10px 0px black",
              "&:hover": {
                backgroundColor: "#f7ba4c",
              },
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "black",
                textTransform: "uppercase",

                fontWeight: "bold",
              }}
            >
              Terms & Conditions
            </Typography>
          </Button>
        </Box>
      </Box>

      <Dialog
        onClose={() => setShowVirtualPetsDialog(false)}
        open={showVirtualPetsDialog}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src="/logo.png" alt="egg" width="300px" />
          <IconButton
            aria-label="close"
            onClick={() => setShowVirtualPetsDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "375px",
            pb: 0,
          }}
        >
          <Typography>
            Get ready for an exciting new feature on our platform. Soon, you'll
            be able to use your tokens to adopt, care for, and grow a unique
            virtual pet. Engage with your pet daily, complete fun activities,
            and watch your pet evolve and thrive. Collect rare items, unlock
            special abilities, and show off your pet to friends!
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
            <Box
              sx={{
                background:
                  "radial-gradient(circle, rgba(0,0,0,1) 60%, #3e608c 70%)",
                borderRadius: "50%",
                width: "250px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/images/pet.gif"
                alt="Alcade"
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: 150,
                  marginTop: 20,
                }}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <TermsAndConditions
        showTermsAndConditions={showTermsAndConditions}
        setShowTermsAndConditions={setShowTermsAndConditions}
      />

      <SweepstakesRules
        showSweepstakesRules={showSweepstakesRules}
        setShowSweepstakesRules={setShowSweepstakesRules}
      />
    </>
  );
};
