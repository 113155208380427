import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Button,
  DialogActions,
} from "@mui/material";
import Iconify from "./Iconify";

export default function TermsAndConditions({
  showTermsAndConditions,
  setShowTermsAndConditions,
}: {
  showTermsAndConditions: boolean;
  setShowTermsAndConditions: (value: boolean) => void;
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={() => setShowTermsAndConditions(false)}
      open={showTermsAndConditions}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DialogTitle>
        Terms & Conditions
        <IconButton
          aria-label="close"
          onClick={() => setShowTermsAndConditions(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="ep:close-bold" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {/* Disclaimer */}
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1050 }}>
          Disclaimer: No Real Money Gambling
        </Typography>

        <Typography sx={{ mt: 2 }}>
          This website/station and the services provided here do not offer "real
          money gambling." No actual money is required to participate, and all
          services are intended solely for entertainment purposes.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Arbitration Clause
        </Typography>

        <Typography sx={{ mt: 2 }}>
          Please be aware that these terms of use contain a mandatory
          arbitration provision. This means that any past, current, or future
          disputes between you and us will be resolved through binding
          arbitration on an individual basis, not through class actions. If you
          do not wish to agree to arbitration for future claims and have not
          previously agreed to arbitration, you may opt-out within thirty (30)
          days by following the instructions provided under "Binding Arbitration
          and Class Waiver" in Section 16 below.{" "}
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Terms of Use
        </Typography>

        <Typography sx={{ mt: 2 }}>
          Please read these Terms of Use, along with our Privacy Policy and
          Official Sweepstakes Rules, before using any services offered on our
          website or application. By continuing to access or use our site or
          services, you agree to be bound by these terms, including our Privacy
          Policy, Official Sweepstakes Rules, and our Responsible Social Gaming
          Policy. These Terms of Use ("Terms" or "TOU") are a legally binding
          agreement between you and Alcade Technologies (referred to as "we,"
          "us," or "our"). They govern your access to our website, your use of
          our games, participation in sweepstakes promotions, and any
          transactions or dealings with us (collectively referred to as
          "Services")
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          1. Changes to Terms
        </Typography>

        <Typography sx={{ mt: 2 }}>
          1.1. We reserve the right to modify these Terms and will notify you of
          material changes via email. It’s your responsibility to review these
          Terms regularly. Continued use of the site or services after any
          updates means you accept the revised Terms. <br /> <br /> 1.2. We may
          also update our Privacy Policy, Sweepstakes Rules, and Responsible
          Social Gaming Policy (collectively, "Incorporated Policies"). By
          continuing to use the services, you agree to be bound by any updates
          to the Incorporated Policies. <br /> <br /> 1.3. Contact customer
          support if you have any questions regarding these Terms or
          Incorporated Policies. <br /> <br /> 1.4. In case of conflict between
          the Terms and the Incorporated Policies, the Terms will take
          precedence.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          2. License and Virtual Coins
        </Typography>

        <Typography sx={{ mt: 2 }}>
          2.1. **Virtual Coins:** Our services may offer virtual, in-game tokens
          such as credits, coins, or points. These tokens may be purchased with
          real money or provided for free. We grant you a personal,
          non-transferable, non-exclusive, revocable license to use these tokens
          for personal entertainment only. You do not own any virtual tokens.{" "}
          <br /> <br />
          2.2. The transfer or sale of virtual coins to other users is
          prohibited. Doing so violates these Terms and may result in account
          suspension or a permanent ban.
          <br /> <br /> 2.3. Purchases of virtual coins are final, and no
          refunds will be issued. Virtual coins will be forfeited if your
          account is suspended, terminated, or if the services are discontinued.
          <br /> <br /> 2.4. You do not acquire ownership rights in any part of
          the service, including virtual coins.
          <br /> <br /> 2.5. Our service is licensed to you and not sold. We and
          our licensors retain ownership of the service and all intellectual
          property associated with it.
          <br /> <br /> 2.6. You agree not to:
          <br /> - Sell, rent, or transfer any part of the service to a third
          party <br />- Copy, modify, or reverse engineer the service <br />-
          Exploit or harass other users within the service
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          3. Eligibility
        </Typography>

        <Typography sx={{ mt: 2 }}>
          To use our services, you must:
          <br /> - Be over 18 years of age or the legal age of majority in your
          jurisdiction <br />- Ensure compliance with all applicable laws in
          your jurisdiction <br />- Use the services for personal, recreational
          purposes only <br />- Not reside in or access the services from
          restricted territories <br />- Provide accurate and truthful
          information during registration <br />- Avoid using unauthorized
          software to manipulate the services{" "}
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          4. User Account
        </Typography>

        <Typography sx={{ mt: 2 }}>
          4.1. You are allowed one account per person. Multiple accounts will
          result in termination.
          <br /> <br /> 4.2. Keep your account details secure and do not share
          them with others. You are responsible for all activity on your
          account.
          <br /> <br /> 4.3. Accounts may be suspended or closed if inactive for
          60 days or more.{" "}
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          5. Games and Contests
        </Typography>

        <Typography sx={{ mt: 2 }}>
          All games and sweepstakes promotions have their own set of rules,
          which are available on our site. Please familiarize yourself with
          these rules before participating.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          6. Purchases of Virtual Coins
        </Typography>

        <Typography sx={{ mt: 2 }}>
          6.1. Only use payment methods that belong to you. Chargebacks may
          result in suspension of your account.
          <br /> <br /> 6.2. All purchases are final, and virtual coins are
          provided in USD. For any issues or questions, you can reach our
          customer support via the "Live Support" link.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          7. Complaints
        </Typography>

        <Typography sx={{ mt: 2 }}>
          7.1. If you have a complaint regarding our service, please contact us
          via the "Live Support" link on our site and complete the form.
          <br />
          <br />
          7.2. All customer complaints or claims must be submitted within three
          (3) months from the date of the incident in question.
          <br /> <br />
          7.3. To maintain your privacy, please ensure that all communications
          are conducted using the email address registered with your account.
          This helps to avoid delays in our response. Please include the
          following details in your complaint: <br /> <br />- Your user ID{" "}
          <br />- Your first and last name as registered on your account <br />-
          A detailed explanation of the issue <br />- Relevant dates and times
          (if applicable) <br /> <br />
          7.4. Failing to provide this information may delay our response. We
          aim to reply within 72 hours of receiving your communication.
          <br /> <br />
          7.5. We will do our best to resolve your complaint quickly. If you are
          unsatisfied with the outcome, arbitration is available as outlined in
          Section 16.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          8. Promotions
        </Typography>

        <Typography sx={{ mt: 2 }}>
          8.1. All promotions, including sweepstakes, contests, and special
          offers, are subject to our Terms, the Official Sweepstakes Rules, and
          any additional terms provided at the time of the promotion.
          <br />
          <br />
          8.2. We reserve the right to withdraw or modify any promotions at any
          time, without prior notice. <br />
          <br />
          8.3. We reserve the right to exclude any user from promotions at our
          discretion. <br />
          <br />
          8.4. If we suspect that you are using multiple accounts or engaging in
          fraudulent or illegal activities, we reserve the right to exclude you
          from promotions and withhold any benefits. Accounts may be suspended
          if found violating these terms.
          <br /> <br /> 8.5. By participating in any promotion, you grant us a
          non-exclusive, royalty-free license to use any content you submit,
          without additional acknowledgment.{" "}
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          9. Verification
        </Typography>

        <Typography sx={{ mt: 2 }}>
          9.1. We reserve the right to conduct necessary verification checks
          (including credit and background checks) as required by law or
          regulation. You agree to comply with these checks in a timely manner.{" "}
          <br />
          <br />
          9.2. Your account may be restricted until we complete the verification
          process. <br />
          <br />
          9.3. Verification documents may include a government-issued ID, a
          utility bill matching your account address, and proof of income or
          funds.
          <br />
          <br /> 9.4. If requested documents are not provided within 40 days, we
          may deactivate or restrict your account.
          <br />
          <br /> 9.5. We may use third-party services for identification and
          verification. You must enable “Location Services” on your device to
          use our services.{" "}
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          10. Intellectual Property
        </Typography>

        <Typography sx={{ mt: 2 }}>
          10.1. These Terms grant you the right to use our service, but do not
          confer ownership rights. <br />
          <br />
          10.2. All intellectual property related to our service, including
          copyrights, patents, and trade secrets, remain our property or that of
          any third-party licensors.
          <br />
          <br /> 10.3. Your use of our games does not give you any ownership of
          their intellectual property, which includes game software, design
          elements, characters, and more. <br />
          <br />
          10.4. You acknowledge that your account is owned by us, and you do not
          have ownership rights to it.{" "}
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          11. User-Generated Content Responsibility{" "}
        </Typography>

        <Typography sx={{ mt: 2 }}>
          11.1. You are responsible for ensuring that all content you submit
          complies with applicable laws and does not violate any rights or
          contain offensive or illegal material. <br /> <br />
          11.2. You must provide accurate information and notify us of any
          changes. Submitting misleading or false information is prohibited.{" "}
          <br /> <br />
          11.3. We reserve the right to delete user-generated content at our
          discretion. We are not obligated to monitor such content. <br />{" "}
          <br /> 11.4. By submitting content, you grant us a worldwide,
          royalty-free license to use, modify, and distribute your content in
          any form. <br /> <br />
          11.5. We may use your name, likeness, and any other materials in
          connection with your content without any obligation to you. <br />{" "}
          <br /> 11.6. You are solely responsible for the risk associated with
          submitting content. We disclaim responsibility for backing up or
          retaining user content. <br /> <br />
          11.7. Prohibited content includes, but is not limited to, content
          that: <br /> <br /> - Promotes racism, bigotry, or violence <br />-
          Harasses or threatens others - Contains offensive or sexual material{" "}
          <br /> - Encourages illegal activity <br />- Involves commercial
          promotions without our approval <br /> <br />
          11.8. If you encounter inappropriate content, you can report it to us
          at support@Alcade.digital.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          12. Third-Party Websites & Content
        </Typography>

        <Typography sx={{ mt: 2 }}>
          12.1. Our service may contain links to third-party websites or
          content. Any obligations incurred with these third parties are your
          responsibility. <br />
          <br />
          12.2. Inclusion of a link does not imply endorsement, and we are not
          responsible for the accuracy or functionality of third-party websites.{" "}
          <br />
          <br />
          12.3. By accessing third-party websites, you accept that we have no
          control over them and are not responsible for their content or privacy
          policies.
          <br />
          <br /> 12.4. We do not assume liability for third-party products,
          services, or content. <br />
          <br />
          12.5. If you engage with social media platforms to discuss our
          service, you agree to follow the platform’s terms and avoid posting
          false or defamatory comments about us.{" "}
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          13. Intellectual Property Infringement
        </Typography>

        <Typography sx={{ mt: 2 }}>
          13.1. We respect the intellectual property rights of others. If you
          believe your rights have been infringed, please contact us at
          intellectualproperty@Alcade.digital. <br /> <br />
          13.2. Your notice must include: <br /> <br />- A signature of the
          rights holder or authorized representative <br />- A description of
          the work you believe has been infringed <br />- Information to locate
          the infringing material on our site <br />- Your contact information{" "}
          <br />- A statement of your good faith belief that the use is
          unauthorized <br />- A statement under penalty of perjury that your
          claim is accurate <br /> <br />
          13.3. Before filing a notice, please ensure the material is not
          protected under Fair Use doctrine.{" "}
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          14. Service Disruptions, Errors & Omissions
        </Typography>

        <Typography sx={{ mt: 2 }}>
          14.1. Our services are provided “as is” without warranties. We make no
          guarantees regarding uninterrupted service, error correction, or
          accuracy of information. <br /> <br />
          14.2. In some jurisdictions, warranty limitations may not apply.{" "}
          <br /> <br />
          14.3. We are not liable for any server disruptions, errors, or
          technical disturbances. <br /> <br /> 14.4. In the event of a system
          malfunction, any gameplay during the issue will be voided. <br />{" "}
          <br />
          14.5. We reserve the right to void gameplay results if an error
          occurs. <br /> <br />
          14.6. We may temporarily suspend service at our discretion and will
          restore it as soon as possible. <br /> <br />
          14.7. If virtual coins or prizes are awarded in error, we reserve the
          right to void them. <br /> <br />
          14.8. In cases of discrepancies, the result shown on our server will
          be the official result.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Indemnity and Limitation of Liability
        </Typography>

        <Typography sx={{ mt: 2 }}>
          15.1. Liability LimitationTo the fullest extent permitted by law, we,
          including our subsidiaries, affiliates, officers, employees, agents,
          partners, and licensors, are not liable for any direct, indirect,
          incidental, special, consequential, or punitive damages. This
          includes, but is not limited to, personal injury, property damage,
          data loss, or any other intangible losses arising from: - Your access
          or use (or inability to use) the service; - Any third-party content or
          conduct on the service; - Information or content obtained from the
          service or reliance on it; - Unauthorized access or changes to your
          data or transmissions. <br /> <br />
          15.2. Jurisdictional Limitations In some jurisdictions, the exclusion
          or limitation of certain liabilities may not apply. In such cases, the
          liability limitations in these terms will be enforced to the fullest
          extent permissible under applicable law. <br /> <br />
          15.3. Maximum Liability Our total liability, including that of our
          affiliates, is limited to the amount you have paid us in the 180 days
          preceding your claim.
          <br /> <br />
          15.4. Service Interruptions We are not liable for damages caused by
          technical issues such as viruses, bugs, hardware or software
          malfunctions, network failures, or other disruptions related to your
          access to or use of the service. We cannot guarantee uninterrupted
          access to the service. <br /> <br />
          15.5. Third-Party Liability We are not responsible for damages or
          liabilities arising from content, products, or services offered by
          third parties in connection with our service. <br /> <br />
          15.6. Release of LiabilityYou waive and release us, along with our
          affiliates, from any liabilities related to third-party actions
          connected to your use of the service. <br /> <br />
          15.7. Indemnity You agree to indemnify and hold us harmless from any
          claims, including legal fees, arising from your use of the service,
          breach of these terms, or violation of any law or third-party rights.{" "}
          <br /> <br />
          15.8. Personal Injury Exception Nothing in these terms limits our
          liability for death or personal injury caused by our negligence or
          willful misconduct.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Dispute Resolution and Agreement to Arbitrate{" "}
        </Typography>

        <Typography sx={{ mt: 2 }}>
          16.1. Binding Arbitration and Class Waiver By agreeing to these terms,
          you agree to arbitrate all disputes with us and waive your right to
          participate in any class, collective, or representative action.
          Arbitration precludes you from suing in court or having a jury trial.
          If you have not agreed to a previous arbitration provision, you may
          opt out within 30 days. <br /> <br />
          16.1.1. Arbitration Scope Any dispute related to your use of our
          services or these terms will be resolved through arbitration, except
          for claims related to intellectual property violations. The arbitrator
          has the authority to determine the scope and validity of this
          arbitration agreement. <br /> <br />
          16.1.2. Waiver of Class Action Whether resolved in arbitration or
          court, you agree not to initiate or participate in class actions or
          other representative proceedings against us.
          <br />
          16.1.3. Opt-Out Option If you haven’t previously agreed to
          arbitration, you can opt out within 30 days of entering this
          agreement. <br /> <br /> 16.1.4. Arbitration Location Arbitration will
          take place in Wilmington, Delaware, and you may participate
          telephonically. It will follow the rules of the American Arbitration
          Association (AAA). <br /> <br /> 16.1.5. Arbitrator's Authority The
          arbitrator may award any remedies available in court, except for those
          related to class actions. <br /> <br />
          16.1.6. Confidentiality All arbitration proceedings and awards will
          remain confidential unless required by law. <br /> <br />
          16.1.7. Arbitration Fees For individual claims, you will only pay $250
          in arbitration fees, with us covering additional costs up to $5,000.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          One-Year Limitation Period
        </Typography>

        <Typography sx={{ mt: 2 }}>
          17.1. One-Year Filing Requirement** Any claim or lawsuit arising from
          these terms or the service must be filed within one year of the
          incident. Claims filed after this period are time-barred.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Miscellaneous
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          18.1. Entire Agreement
        </Typography>

        <Typography sx={{ mt: 2 }}>
          These terms represent the complete agreement between you and us
          regarding the service and supersede all prior agreements. <br />
          <br /> 18.2. Taxes You are responsible for any applicable taxes
          related to your use of the service.
          <br /> <br /> 18.3. Force Majeure We are not liable for delays or
          failures caused by events beyond our control. <br /> <br />
          18.4. Severability If any provision of these terms is found invalid or
          unenforceable, the remaining provisions will continue in full force.{" "}
          <br /> <br /> 18.5. Assignment You may not transfer or assign these
          terms without our written consent. We may assign our rights and
          obligations without notice to you. <br /> <br />
          18.6. Business Transfers In the event of a business transfer, your
          account and data may be included in the transferred assets. <br />
          <br /> 18.7. Waiver Our failure to enforce any provision of these
          terms does not constitute a waiver of our rights.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Governing Law and Jurisdiction
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          19.1. Governing Law
        </Typography>

        <Typography sx={{ mt: 2 }}>
          These terms are governed by the laws of Delaware, USA, without regard
          to conflict of law principles. **19.2. Jurisdiction** Any disputes
          will be resolved in the courts of Delaware, except for motions to
          compel arbitration or enforce arbitral awards, which can be brought in
          any competent court.
        </Typography>
      </DialogContent>

      <DialogActions sx={{}}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            setShowTermsAndConditions(false);
          }}
          sx={{ color: "white" }}
          size="large"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
