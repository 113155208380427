import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Button,
  DialogActions,
} from "@mui/material";
import Iconify from "./Iconify";

// ## Sweepstakes Rules and Terms of Service

// Welcome to Alcade Technologies. These **Sweepstakes Rules** and **Terms of Service** govern your use of Alcade Technologies online sweepstakes services. By participating in our sweepstakes games, you acknowledge and agree to the following terms and conditions. These rules are designed to comply with relevant sweepstakes laws and ensure a fair and lawful gaming experience for all participants.

// ### 1. **Eligibility**

// - **Age Requirement**: Participants must be at least 18 years old (or older, if required by the applicable jurisdiction).
// - **Jurisdictional Restrictions**: Due to varying state and national laws, participation in our sweepstakes may be restricted in certain jurisdictions. By accessing or using our platform, you confirm that participation is legally permissible in your jurisdiction.
// - **Prohibited Individuals**: Employees, contractors, and affiliates of Alcade Technologies and their immediate family members are not eligible to participate in the sweepstakes.

// ### 2. **No Purchase Necessary**

// - Our sweepstakes does **not require any purchase to play** or win. You can enter and play our games for free, as required by applicable sweepstakes laws.
// - **Alternative Methods of Entry (AMOE)**: An alternative entry method, such as sending a postcard to our customer service, is provided to allow participants to enter the sweepstakes without purchase. Details for AMOE are provided below in Section 7.

// ### 3. **Sweepstakes Currency: Alcade Coins and Sweepstakes Coins**

// - **Alcade Coins**: Gold Coins are for entertainment and are **non-redeemable** for cash. They can be used to play games but have no monetary value and are not refundable. In the near future you will be able to use your Alcade Coins to purchase in app digital creatures that you can level up!
// - **Sweepstakes Coins (SC)**: Sweepstakes Coins are used to enter sweepstakes games. They may be won or obtained through AMOE and have no direct monetary value; however, winnings using Sweepstakes Coins can be redeemed for cash prizes, subject to the Redemption Policy in Section 6.

// ### 4. **How to Play and Win**

// - **Sweepstakes Drawings**: Participants who enter sweepstakes games with Sweepstakes Coins may qualify for prizes. Winnings may vary depending on the game, and all odds of winning are disclosed before entry.
// - **Game Fairness**: All sweepstakes games are based on randomly generated outcomes, ensuring fair play and adherence to applicable regulations. Alcade Technologies employs industry-standard technologies to guarantee random, unbiased results.
// - **Prizes**: Prizes for sweepstakes games may include cash or other rewards. All prizes are awarded as per the rules outlined in each individual game, and winners are selected at random.

// ### 5. **Sweepstakes Rules Compliance**

// - Alcade Technologies operates in compliance with **U.S. sweepstakes laws** as well as **state regulations**, which may impose restrictions on internet gaming or sweepstakes.
// - **Prohibited Locations**: Some U.S. states, including but not limited to Washington and Idaho, prohibit online sweepstakes. Players are responsible for ensuring that they are not participating in prohibited jurisdictions.

// ### 6. **Redemption Policy**

// - **Eligibility for Redemption**: Only winnings earned using Sweepstakes Coins are eligible for redemption.
// - **Redemption Limits**: To redeem winnings, participants may be subject to a minimum threshold, which will be disclosed on our website.
// - **Verification and Processing**: Prior to redemption, winners may be required to verify their identity to comply with federal and state anti-money laundering laws. Processing times may vary, and Alcade Technologies reserves the right to conduct additional security checks.

// ### 7. **Alternative Method of Entry (AMOE)**

// - Participants may obtain Sweepstakes Coins by entering through our AMOE without making any purchases.
// - **How to Enter via AMOE**: To participate without purchase, send a 3x5 card with your full name, email address, mailing address, and a request to receive free Sweepstakes Coins to 936 St Catherine St E, H2L 2E5 Montreal Canada. Limit one entry per day via AMOE.

// ### 8. **Sweepstakes Taxes**

// - **Prize Tax Responsibility**: Winners are solely responsible for any tax implications associated with their winnings. Any prize won through sweepstakes may be subject to federal, state, and local taxes, and Alcade Technologies may be required to report certain winnings to the IRS.
// - **Reporting Requirements**: In the event of a significant prize (over $600), winners may be required to provide tax documentation, including a W-9 form, for compliance with IRS requirements.

// ### 9. **Privacy Policy**

// - Alcade Technologies values your privacy and is committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and share your data.

// ### 10. **Dispute Resolution**

// - In the event of a dispute regarding eligibility, participation, or prize awards, Alcade Technologies reserves the right to make a final decision. We encourage open communication and provide a customer support team to resolve any disputes fairly and quickly.
// - **Arbitration Clause**: Disputes arising out of these sweepstakes terms shall be resolved through binding arbitration in accordance with [Jurisdiction’s] laws.

// ### 11. **Changes to Terms**

// - Alcade Technologies reserves the right to modify these rules at any time to ensure compliance with legal standards and enhance user experience. Players will be notified of significant changes via email or website announcements.

// ### 12. **Contact Information**

// - For questions regarding our Sweepstakes Rules or Terms of Service, please contact our customer support at support@Alcade.digital

// **By participating in Alcade Technologies sweepstakes, you agree to comply with these rules and terms. Violation of these terms may result in forfeiture of winnings, account suspension, or permanent exclusion.**

export default function SweepstakesRules({
  showSweepstakesRules,
  setShowSweepstakesRules,
}: {
  showSweepstakesRules: boolean;
  setShowSweepstakesRules: (value: boolean) => void;
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={() => setShowSweepstakesRules(false)}
      open={showSweepstakesRules}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DialogTitle>
        Sweepstakes Rules
        <IconButton
          aria-label="close"
          onClick={() => setShowSweepstakesRules(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="ep:close-bold" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography gutterBottom sx={{ fontSize: "18px", mt: 300, mb: 5 }}>
          Welcome to Alcade Technologies. These <b>Sweepstakes Rules</b> and{" "}
          <b>Terms of Service</b> govern your use of Alcade Technologies online
          sweepstakes services. By participating in our sweepstakes games, you
          acknowledge and agree to the following terms and conditions. These
          rules are designed to comply with relevant sweepstakes laws and ensure
          a fair and lawful gaming experience for all participants.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>1. Eligibility**</b>
          <br />- <b>Age Requirement</b>: Participants must be at least 18 years
          old (or older, if required by the applicable jurisdiction).
          <br />- <b>Jurisdictional Restrictions</b>: Due to varying state and
          national laws, participation in our sweepstakes may be restricted in
          certain jurisdictions. By accessing or using our platform, you confirm
          that participation is legally permissible in your jurisdiction.
          <br />- <b>Prohibited Individuals</b>: Employees, contractors, and
          affiliates of Alcade Technologies and their immediate family members
          are not eligible to participate in the sweepstakes.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>2. No Purchase Necessary</b>
          <br />
          - Our sweepstakes does **not require any purchase to play** or win.
          You can enter and play our games for free, as required by applicable
          sweepstakes laws.
          <br />- <b>Alternative Methods of Entry (AMOE)</b>: An alternative
          entry method, such as sending a postcard to our customer service, is
          provided to allow participants to enter the sweepstakes without
          purchase. Details for AMOE are provided below in Section 7.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>3. Sweepstakes Currency: Alcade Coins and Sweepstakes Coins</b>
          <br />- <b>Alcade Coins</b>: Gold Coins are for entertainment and are
          **non-redeemable** for cash. They can be used to play games but have
          no monetary value and are not refundable. In the near future you will
          be able to use your Alcade Coins to purchase in app digital creatures
          that you can level up!
          <br />- <b>Sweepstakes Coins (SC)</b>: Sweepstakes Coins are used to
          enter sweepstakes games. They may be won or obtained through AMOE and
          have no direct monetary value; however, winnings using Sweepstakes
          Coins can be redeemed for cash prizes, subject to the Redemption
          Policy in Section 6.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>4. How to Play and Win</b>
          <br />- <b>Sweepstakes Drawings</b>: Participants who enter
          sweepstakes games with Sweepstakes Coins may qualify for prizes.
          Winnings may vary depending on the game, and all odds of winning are
          disclosed before entry.
          <br />- <b>Game Fairness</b>: All sweepstakes games are based on
          randomly generated outcomes, ensuring fair play and adherence to
          applicable regulations. Alcade Technologies employs industry-standard
          technologies to guarantee random, unbiased results.
          <br />- <b>Prizes</b>: Prizes for sweepstakes games may include cash
          or other rewards. All prizes are awarded as per the rules outlined in
          each individual game, and winners are selected at random.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>5. Sweepstakes Rules Compliance</b>
          <br />- Alcade Technologies operates in compliance with **U.S.
          sweepstakes laws** as well as **state regulations**, which may impose
          restrictions on internet gaming or sweepstakes.
          <br />- **Prohibited Locations**: Some U.S. states, including but not
          limited to Washington and Idaho, prohibit online sweepstakes. Players
          are responsible for ensuring that they are not participating in
          prohibited jurisdictions.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>6. Redemption Policy</b>
          <br />- <b>Eligibility for Redemption</b>: Only winnings earned using
          Sweepstakes Coins are eligible for redemption.
          <br />- <b>Redemption Limits</b>: To redeem winnings, participants may
          be subject to a minimum threshold, which will be disclosed on our
          website.
          <br />- <b>Verification and Processing</b>: Prior to redemption,
          winners may be required to verify their identity to comply with
          federal and state anti-money laundering laws. Processing times may
          vary, and Alcade Technologies reserves the right to conduct additional
          security checks.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>7. Alternative Method of Entry (AMOE)</b>
          <br />- Participants may obtain Sweepstakes Coins by entering through
          our AMOE without making any purchases.
          <br />- **How to Enter via AMOE**: To participate without purchase,
          send a 3x5 card with your full name, email address, mailing address,
          and a request to receive free Sweepstakes Coins to 936 St Catherine St
          E, H2L 2E5 Montreal Canada. Limit one entry per day via AMOE.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>8. Sweepstakes Taxes</b>
          <br />- **Prize Tax Responsibility**: Winners are solely responsible
          for any tax implications associated with their winnings. Any prize won
          through sweepstakes may be subject to federal, state, and local taxes,
          and Alcade Technologies may be required to report certain winnings to
          the IRS.
          <br />- **Reporting Requirements**: In the event of a significant
          prize (over $600), winners may be required to provide tax
          documentation, including a W-9 form, for compliance with IRS
          requirements.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>9. Privacy Policy</b>
          <br />- Alcade Technologies values your privacy and is committed to
          protecting your personal information. Our Privacy Policy outlines how
          we collect, use, and share your data.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>10. Dispute Resolution</b>
          <br />- In the event of a dispute regarding eligibility,
          participation, or prize awards, Alcade Technologies reserves the right
          to make a final decision. We encourage open communication and provide
          a customer support team to resolve any disputes fairly and quickly.
          <br />- **Arbitration Clause**: Disputes arising out of these
          sweepstakes terms shall be resolved through binding arbitration in
          accordance with [Jurisdiction’s] laws.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>11. Changes to Terms</b>
          <br />- Alcade Technologies reserves the right to modify these rules
          at any time to ensure compliance with legal standards and enhance user
          experience. Players will be notified of significant changes via email
          or website announcements.
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>12. Contact Information</b>
          <br />- For questions regarding our Sweepstakes Rules or Terms of
          Service, please contact our customer support at support@Alcade.digital
        </Typography>

        <Typography gutterBottom sx={{ fontSize: "18px", mb: 2 }}>
          <b>
            By participating in Alcade Technologies sweepstakes, you agree to
            comply with these rules and terms. Violation of these terms may
            result in forfeiture of winnings, account suspension, or permanent
            exclusion.
          </b>
        </Typography>
      </DialogContent>

      <DialogActions sx={{}}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            setShowSweepstakesRules(false);
          }}
          sx={{ color: "white" }}
          size="large"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
