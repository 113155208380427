import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import Sidebar from "./sidebar";
// import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Sidebar />,
      children: [
        { path: "/", element: <PageHome /> },
        { path: "game", element: <PageGame /> },
        { path: "mockgame", element: <PageMockGame /> },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "/set-password", element: <PageSetPassword /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageHome = Loadable(lazy(() => import("../pages/PageHome")));
const PageGame = Loadable(lazy(() => import("../pages/PageGame")));
const PageMockGame = Loadable(lazy(() => import("../pages/PageMockGame")));
const PageSetPassword = Loadable(
  lazy(() => import("../pages/PageSetPassword"))
);
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
