import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { fs, invoke } from "@tauri-apps/api";
import { BaseDirectory } from "@tauri-apps/api/path";
import { Auth } from "../Auth";
import { Buffer } from "buffer";
import { getVersion } from "@tauri-apps/api/app";
import * as x509 from "@peculiar/x509";
import axios from "axios";
import posthog from "posthog-js";

// ----------------------------------------------------------------------

const auth = new Auth();

interface loginInput {
  playerId?: string;
  cardSerialNumber?: string;
  email?: string;
  password?: string;
}

interface logoutInput {
  withCashout: boolean;
}

enum GameSource {
  EQUINOX = "EQUINOX",
  GMW = "GMW",
  INTERNAL = "INTERNAL",
}

interface AuthContextType {
  gamesList: any[];
  gamesLastFetched: number | null;
  playerId: string | undefined;
  username: string | undefined;
  playerSessionId: string | undefined;
  entry: string | undefined;
  winnings: string | undefined;
  aleonTokens: string | undefined;
  cert: string | undefined;
  showLoginForm: boolean;
  isTauriApp: boolean;
  isMuted: boolean;

  // Functions
  login: (loginInput: loginInput) => Promise<boolean>;
  logout: (logoutInput: logoutInput) => void;
  forgotPassword: (email: string) => Promise<boolean | undefined>;
  setPassword: (code: string, password: string) => Promise<boolean | undefined>;
  getBalance: () => void;
  launchGame: (gameId: number) => void;
  endGameSession: (gameSessionId: string) => void;
  setShowLoginForm: (showLoginForm: boolean) => void;
  setIsMuted: (isMuted: boolean) => void;
  initializeStation: (secret: string) => void;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
};

export const StationAuthProvider = ({ children }: AuthProviderProps) => {
  const [gamesList, _setGamesList] = useState<any[]>([]);
  const [gamesLastFetched, _setGamesLastFetched] = useState<number | null>(
    null
  );
  const [playerId, _setPlayerId] = useState<string | undefined>(undefined);
  const [username, _setUsername] = useState<string | undefined>(undefined);
  const [playerSessionId, _setPlayerSessionId] = useState<string | undefined>(
    undefined
  );
  const [entry, _setEntry] = useState<string | undefined>(undefined);
  const [winnings, _setWinnings] = useState<string | undefined>(undefined);
  const [aleonTokens, _setAleonTokens] = useState<string | undefined>(
    undefined
  );
  const [cert, _setCert] = useState<string | undefined>(undefined);
  const [showLoginForm, _setShowLoginForm] = useState(false);
  const [isTauriApp, _setIsTauriApp] = useState(false);
  const [isMuted, _setIsMuted] = useState(false);

  // functions
  const getBalance = async () => {
    if (!playerId) return;
    if (!localStorage.getItem("jwt")) return;

    const getBalanceResponse = await axios.get(
      import.meta.env.VITE_APP_API + `/station/getBalance/${playerId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    _setWinnings(getBalanceResponse.data.winnings);
    _setEntry(getBalanceResponse.data.entry);
    _setAleonTokens(getBalanceResponse.data.aleonTokens);
  };

  const login = async (loginInput: loginInput): Promise<boolean> => {
    const { playerId, cardSerialNumber, email, password } = loginInput;
    if (window.__TAURI__) {
      try {
        const certfile = await fs.readBinaryFile("station-cert.pem", {
          dir: BaseDirectory.AppData,
        });
        const cert = Buffer.from(certfile).toString("base64");
        _setCert(cert);
        const { subject } = new x509.X509Certificate(certfile);
        const parsedSubject = parseCertificateSubject(subject);
        const stationId = parsedSubject["CN"];
        const message = `${playerId}-${stationId}`;
        const base64EncodedMessage = Buffer.from(message).toString("base64");
        const signature = await invoke("get_signature", {
          base64Message: base64EncodedMessage,
        });
        if (!playerId || !cardSerialNumber) return false;
        const response = await auth.loginWithTauri(
          playerId,
          cardSerialNumber,
          cert,
          signature as string
        );
        _setUsername(response.username);
        _setPlayerId(response.playerId);
        _setPlayerSessionId(response.playerSessionId);
        localStorage.setItem("jwt", response.token);
        localStorage.setItem("refreshToken", response.refreshToken);

        if (
          !import.meta.env.VITE_APP_API.includes("localhost") &&
          !import.meta.env.VITE_APP_API.includes("staging") &&
          posthog
        ) {
          posthog.identify(
            response.playerId, // Unique ID for the user
            {
              username: response.username,
              playerSessionId: response.playerSessionId,
            }
          );
        }

        return true;
      } catch {
        return false;
      }
    } else {
      if (!email || !password) return false;
      try {
        // Web App
        const response = await auth.login(email, password);
        _setPlayerId(response.playerId);
        _setUsername(response.username);
        _setPlayerSessionId(response.playerSessionId);
        localStorage.setItem("jwt", response.token);
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("playerSessionId", response.playerSessionId);
        return true;
      } catch {
        return false;
      }
    }
  };

  const forgotPassword = async (email: string) => {
    if (!email) return;
    try {
      const response = await auth.forgotPassword(email);

      if (response.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setPassword = async (code: string, password: string) => {
    if (!code || !password) return;
    try {
      const response = await auth.setPassword(code, password);
      if (response.success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const logout = async ({ withCashout }: logoutInput) => {
    if (!withCashout) await auth.logout(playerSessionId!);
    _setPlayerId(undefined);
    _setUsername(undefined);
    _setPlayerSessionId(undefined);
    _setEntry(undefined);
    _setWinnings(undefined);
    _setAleonTokens(undefined);
    localStorage.removeItem("jwt");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("playerSessionId");
  };

  const setIsMuted = (_isMuted: boolean) => _setIsMuted(_isMuted);

  const setShowLoginForm = (_showLogInForm: boolean) =>
    _setShowLoginForm(_showLogInForm);

  const initializeStation = async (secret: string) => {
    if (window.__TAURI__) {
      const stationData = await auth.getStationData(secret);
      if (stationData == null) {
        throw new Error("Failed to get station data");
      }

      await invoke("generate_certificate", {
        roomId: stationData.roomId,
        stationId: stationData.id,
      });

      const certificate = await fs.readBinaryFile("station-cert.pem", {
        dir: BaseDirectory.AppData,
      });

      const stationAppVersion = await getVersion();

      await auth.initializeStation(
        secret,
        Buffer.from(certificate).toString("base64"),
        stationAppVersion as string
      );
    }
  };
  const navigate = useNavigate();

  const launchGame = async (gameId: number) => {
    if (!playerId) {
      setShowLoginForm(true);
      return;
    }
    if (!localStorage.getItem("jwt")) return;
    let url = "";
    let RequestBody = {};
    const game = gamesList.find((_game) => _game.id === gameId);
    console.info(game);
    if (!game) return;

    // EQX Game
    if (game.source == GameSource.EQUINOX) {
      url = import.meta.env.VITE_APP_API + `/station/createEQXGameSession`;
      RequestBody = {
        gameId: gameId,
        playerSessionId: playerSessionId,
        returnUrl: "http://localhost:3000",
      };
    }
    // GMW Game
    if (game.source == GameSource.GMW) {
      url = import.meta.env.VITE_APP_API + `/station/startGMWGame`;
      RequestBody = {
        gameId: gameId,
        playerSessionId: playerSessionId,
        lobbyURL: "http://localhost:3000",
      };
    }

    const launchGameResponse = await axios.post(url, RequestBody, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    const redirectURL = `gameURL=${launchGameResponse.data.gameUrl}&gameSessionId=${launchGameResponse.data.gameSessionId}`;
    const base64RedirectURL = Buffer.from(redirectURL).toString("base64");
    navigate(`game/?token=${base64RedirectURL}`);
  };

  const endGameSession = async (gameSessionId: string) => {
    if (!playerId) {
      setShowLoginForm(true);
      return;
    }
    if (!localStorage.getItem("jwt")) return;

    const endGameSessionResponse = await axios.post(
      import.meta.env.VITE_APP_API + `/station/endGameSession`,
      {
        gameSessionId: gameSessionId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    if (endGameSessionResponse.data.success) navigate("/");
  };

  useEffect(() => {
    const getGamesList = async () => {
      const now = Date.now();
      const thirtyMinutes = 30 * 60 * 1000;
      // Only fetch if cache has expired
      if (!gamesLastFetched || now - gamesLastFetched > thirtyMinutes) {
        const gamesListResponse = await axios.get(
          import.meta.env.VITE_APP_API + "/station/getActiveGamesList"
        );
        _setGamesList(gamesListResponse.data.games);
        _setGamesLastFetched(now);
      }
    };
    getGamesList();
  }, [window.location.href, gamesLastFetched]);

  // Sets if tauri app or web
  useEffect(() => {
    const initialize = async () => {
      if (window.__TAURI__) _setIsTauriApp(true);
      else _setIsTauriApp(false);
    };
    initialize();
  }, []);

  // Gets Balance on login
  useEffect(() => {
    getBalance();
  }, [playerId]);

  // Checks for existing web app login session
  useEffect(() => {
    const initialize = async () => {
      const jwt = localStorage.getItem("jwt");
      const _playerSessionId = localStorage.getItem("playerSessionId");
      if (jwt && !window.__TAURI__ && _playerSessionId) {
        const isStale = auth.isJwtStale(jwt!);
        if (!isStale) {
          _setPlayerSessionId(_playerSessionId);
          const decodedJwt = decodeJWT(jwt!);
          const playerId = decodedJwt.payload.playerId;
          const playerUsername = decodedJwt.payload.username;
          _setPlayerId(playerId);
          _setUsername(playerUsername);
        }
      }
    };
    initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        entry,
        winnings,
        aleonTokens,
        gamesList,
        gamesLastFetched,
        showLoginForm,
        isTauriApp,
        isMuted,
        playerId,
        username,
        playerSessionId,
        cert,
        // Functions
        getBalance,
        login,
        logout,
        forgotPassword,
        setPassword,
        launchGame,
        endGameSession,
        setShowLoginForm,
        setIsMuted,
        initializeStation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useStationAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "useStationAuth must be used within an StationAuthProvider"
    );
  }
  return context;
};

// Helper Functions
function base64urlDecode(str: string) {
  return decodeURIComponent(
    atob(str.replace(/_/g, "/").replace(/-/g, "+"))
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}
function decodeJWT(token: string) {
  const parts = token.split(".");
  if (parts.length !== 3) throw new Error("Invalid JWT token");
  const header = JSON.parse(base64urlDecode(parts[0]));
  const payload = JSON.parse(base64urlDecode(parts[1]));

  return {
    header: header,
    payload: payload,
    signature: parts[2],
  };
}

const parseCertificateSubject = (subject: string) => {
  const keyValuePairs = subject
    .split(",")
    .map((pair) => pair.trim().split("="));
  const subjectObject = keyValuePairs.reduce(
    (acc: { [key: string]: string }, [key, value]: any) => {
      acc[key] = value;
      return acc;
    },
    {}
  );
  return subjectObject;
};
